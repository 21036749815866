import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { addToken } from './authSlice';

const initialState = {
    mobile: null,
    otpSent: false,
    error: null,
    loading: false
};

const otpVerificationAsync = createAsyncThunk(
    'register/otpVerificationAsync',
    async (credentials, { dispatch, rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/otp/verify`, {
                code: credentials.code,
                mobile: credentials.mobile
            });

            console.log('response', response);

            if (response.status === (201 || 200)) {
                dispatch(addToken(response.data));
                return;
            }

            return rejectWithValue(response);
            // dispatch(addToken(response.data));

            // return response.data;
            return;
        }
        catch (error) {
            console.log('Login failed', error);
            throw new Error(error.response.data.message);
        }
    });

const otpSlice = createSlice({
    name: 'otp',
    initialState,
    reducers: {
        clearOtpError: (state) => {
            state.registerError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(otpVerificationAsync.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(otpVerificationAsync.rejected, (state, action) => {
                console.log('action', action);
                state.error = action.error.message;
                state.loading = false;
            }).addCase(otpVerificationAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            });
    }
});

export const { clearOtpError } = otpSlice.actions;
export { otpVerificationAsync };
export default otpSlice.reducer;