// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './Home';
import Chain from './Chains/Chain';
import Login from './Auth/Login';
import BottomMenu from './Menu/BottomMenu';
import ListOfChains from './Chains/ListOfChains';
import Profile from './Profile';
import { useSelector } from 'react-redux';
import ProtectedLayout from './Auth/ProtectedLayout';
import { useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { useState } from 'react';
import ChainOnBoarding from './Chains/ChainOnBoarding';
import LeaderBoard from './LeaderBoard';
import Register from './Auth/Register';
import OtpVerification from './Auth/OtpVerification';

function App() {
  const [isReady, setReady] = useState(false);

  let token = useSelector((state) => state.auth?.token);


  useEffect(() => {
    setReady(true);
    const id = axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          config.headers.Authorization = `NO TOKEN`;
        }
  
        return config;
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(id);
    };
  },[token]);



  return isReady ? (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/" element={<Login />} />
        <Route path="/chain/:chainId" element={<Chain />} />
        <Route element={<ProtectedLayout />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/chains" element={<ListOfChains />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
        </Route>
          {/* <Route path="/chain" element={<Chain />} /> */}
      </Routes>
      {/* {token && <BottomMenu />} */}

    </Router>
  ) : 'Loading';
}

export default App;
