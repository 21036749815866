import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Icon, Input, Link, Text, useToast } from "@chakra-ui/react";
import { clearLoginError, loginAsync, loginMobileAsync } from '../../Slicers/loginSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Chain from '../../Chains/Chain';
import Localization from '../../Assets/Lang/Localization.json';
import { otpLogin, setLang } from '../../Slicers/authSlice';
import { GrLanguage } from "react-icons/gr";


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mobile, setMobile] = useState('');

    let token = useSelector((state) => state.auth.token);
    let lang = useSelector((state) => state.auth.lang);
    let otpSent = useSelector((state) => state.login.otpSent);
    let loginError = useSelector((state) => state.login.loginError);
    let loginLoading = useSelector((state) => state.login.loading);


    useEffect(() => {
        if (otpSent) {
            console.log("otpSent: ", otpSent);
            dispatch(otpLogin())
            navigate('/otp-verification');
        }
    }, [otpSent]);

    useEffect(() => {
        if (loginError) {
            toast({
                title: "Login Error",
                description: loginError,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            dispatch(clearLoginError())
        }
    }, [loginError]);




    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMobileChange = (e) => {
        setMobile(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);

        dispatch(loginMobileAsync({ mobile }));



        // Add your login logic here
    };

    const changeLang = () => {
        dispatch(setLang());
    }

    return (
        <form style={{ height: '100dvh' }} className="flex justify-center flex-col h-full login-form p-5" onSubmit={handleSubmit}>
            <Icon className='absolute m-2 right-0 top-0' color="teal.500" onClick={changeLang} as={GrLanguage} boxSize={6} marginRight={2} />
            <div className='flex justify-center mb-20'>
                <Text className='' fontSize={'xxx-large'}>{Localization.auth.login[lang]}</Text>
            </div>
            <FormControl className='mb-10'>
                <Input dir={lang == 'ar' && 'rtl'} placeholder={Localization.auth.mobile[lang]} value={mobile} onChange={handleMobileChange} />
            </FormControl>
            {/* <FormControl>
                <Input dir={lang == 'ar' && 'rtl'} placeholder={Localization.auth.password[lang]} type="password" value={password} onChange={handlePasswordChange} />
            </FormControl> */}

            <div className="flex flex-col self-center justify-center mt-10 w-full">
                <div className='flex flex-row mt-2'>
                    <Button
                        isLoading={loginLoading}
                        type="submit"
                        loadingText={Localization.auth.loading[lang]}
                        colorScheme='teal'
                        variant='solid'
                        zIndex={20}
                        className='w-full'
                        marginRight={2}
                    >
                        {Localization.auth.login[lang]}
                    </Button>
                </div>
                <Text className='flex mt-4 justify-center'>
                    <Link color='teal.500'>
                        {Localization.auth.forgotPassword[lang]}
                    </Link>
                </Text>
                <Text className='flex mt-4 justify-center'>
                    <Link color='teal.500' onClick={() => navigate('/register')}>
                        {Localization.auth.register[lang]}
                    </Link>
                </Text>

            </div>
        </form>
    );
};

export default Login;;