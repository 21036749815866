import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { addToken } from './authSlice';

const initialState = {
    loginError: null,
    id: null,
    mobile: null,
    otpSent: false,
    loading: false
};

const loginAsync = createAsyncThunk(
    'login/loginAsync',
    async (credentials, { dispatch }) => {
        try {
            const response = await axiosInstance.post(`/auth/login`, {
                email: credentials.email,
                password: credentials.password,
            });

            dispatch(addToken(response.data));

            return response.data;
        }
        catch (error) {
            console.log('Login failed', error);
            throw new Error(error.response.data.message);
        }
    });

const loginMobileAsync = createAsyncThunk(
    'login/loginMobileAsync',
    async (credentials, { dispatch, rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/auth/login-mobile`, {
                mobile: credentials.mobile
            });

            if (response.status === (200 || 201)) {
                return { mobile: credentials.mobile };
            }
            // dispatch(addToken(response.data));
            rejectWithValue(response);
        }
        catch (error) {
            console.log('Login failed', error);
            throw new Error(error.response.data.message);
        }
    });

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        clearLoginError: (state) => {
            state.loginError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(loginAsync.rejected, (state, action) => {
                console.log('action', action);
                state.loginError = action.error.message;
                state.loading = false;
            }).addCase(loginAsync.pending, (state) => {
                state.loading = true;
                state.loginError = null;
            }).addCase(loginMobileAsync.fulfilled, (state, action) => {
                state.mobile = action.payload.mobile;
                state.otpSent = true;
                state.loading = false;
            })
            .addCase(loginMobileAsync.rejected, (state, action) => {
                console.log('action', action);
                state.loginError = action.error.message;
                state.otpSent = true;
                state.loading = false;
            }).addCase(loginMobileAsync.pending, (state) => {
                state.loading = true;
                state.loginError = null;
            });
    }
});

export const { clearLoginError } = loginSlice.actions;
export { loginAsync, loginMobileAsync };
export default loginSlice.reducer;