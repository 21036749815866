import React, { useEffect, useState } from 'react';
import { Button, FormControl, Input, Link, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Localization from '../../Assets/Lang/Localization.json';
import { otpVerificationAsync } from '../../Slicers/otpSlice';


const OtpVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [otp, setOtp] = useState('');
    const [mobile, setMobile] = useState('');

    // let token = useSelector((state) => state.auth.token);
    // Auth
    let lang = useSelector((state) => state.auth.lang);
    let token = useSelector((state) => state.auth.token);
    let otpCurrentType = useSelector((state) => state.auth.otpCurrentType);
    

    // Register
    let registerMobile = useSelector((state) => state.register.mobile);
    let otpLoading = useSelector((state) => state.register.otpLoading);

    // Login
    let loginMobile = useSelector((state) => state.login.mobile);


    useEffect(() => {
        if (token) {
            navigate('/chains');
            console.log("Token: ", token);
        }
    }, [token]);

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('mobile', mobile);
        console.log('otp', otp);
        
        

        dispatch(otpVerificationAsync({ mobile: otpCurrentType === 'login' ?  loginMobile : registerMobile, code: otp }));


    };

    return (
        <form style={{ height: '100dvh' }} className="flex justify-center flex-col h-full login-form p-5" onSubmit={handleSubmit}>
            <div className='flex justify-center mb-20'>
                <Text className='' fontSize={'xxx-large'}>{Localization.auth.otpVerification[lang]}</Text>

            </div>
            <FormControl className='mb-5' >
                <Input dir={lang == 'ar' && 'rtl'} placeholder={Localization.auth.mobile[lang]} value={otp} onChange={handleOtpChange} />
            </FormControl>

            <div className="flex flex-col self-center justify-center mt-10 w-full">
                <div className='flex flex-row mt-2'>
                    <Button
                        isLoading={otpLoading}
                        type="submit"
                        loadingText={Localization.auth.loading[lang]}
                        colorScheme='teal'
                        variant='solid'
                        zIndex={20}
                        className='w-full'
                        marginRight={2}
                    >
                        {Localization.auth.otpVerification[lang]}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default OtpVerification;