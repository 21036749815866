import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { addToken } from './authSlice';

const initialState = {
    registerError: null,
    id: null,
    mobile: null,
    loading: false,
};

const registerAsync = createAsyncThunk(
    'register/registerAsync',
    async (credentials, { dispatch }) => {
        try {
            const response = await axiosInstance.post(`/auth/register`, {
                email: credentials.email,
                name: credentials.name,
                password: credentials.password,
            });

            dispatch(addToken(response.data));

            return response.data;
        }
        catch (error) {
            console.log('Login failed', error);
            throw new Error(error.response.data.message);
        }
    });

const registerByMobileAsync = createAsyncThunk(
    'register/registerByMobileAsync',
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await axiosInstance.post(`/auth/register-mobile`, {
                name: credentials.name,
                mobile: credentials.mobile
            });

            console.log('response', response);

            if (response.status === 200) {
                // fulfillWithValue(credentials.mobile);
                return { mobile: credentials.mobile };
            }

            return rejectWithValue(response);

            return;
        }
        catch (error) {
            console.log('Login failed', error);
            throw new Error(error.response.data.message);
        }
    });

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        clearRegisterError: (state) => {
            state.registerError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerAsync.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(registerAsync.rejected, (state, action) => {
                console.log('action', action);
                state.registerError = action.error.message;
                state.loading = false;
            }).addCase(registerAsync.pending, (state) => {
                state.loading = true;
                state.registerError = null;
            })
            .addCase(registerByMobileAsync.fulfilled, (state, action) => {
                state.mobile = action.payload.mobile;
                state.loading = false;
                state.otpSent = true;
            })
            .addCase(registerByMobileAsync.rejected, (state, action) => {
                console.log('action', action);
                state.registerError = action.error.message;
                state.otpSent = false;
                state.loading = false;
            }).addCase(registerByMobileAsync.pending, (state) => {
                state.loading = true;
                state.registerError = null;
            });
    }
});

export const { clearRegisterError } = registerSlice.actions;
export { registerAsync, registerByMobileAsync };
export default registerSlice.reducer;