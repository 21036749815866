import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Level from './Level';
import { fetchByChainId, acceptChain, chainOnboarding, finishOnboardingChain, clearErrors, getChains } from '../../Slicers/chainSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Textarea,
  Box,
  Text,
  Image,
  Icon,
  Divider,
  useSteps,
  Card,
  Grid,
  Flex
} from '@chakra-ui/react'
import * as moment from "moment";

import LoadingComponent from '../../Loading';
import BottomMenu from '../../Menu/BottomMenu';
import Localization from '../../Assets/Lang/Localization.json';
import onBoardingPopup from './OnBoardingPopup';
import { finishedOnBoarded } from '../../Slicers/authSlice';

// info
import { CgInfo } from "react-icons/cg";
import { PiUsersThreeFill } from 'react-icons/pi';

// import OwnChainPopup from './OwnChainPopup';

const Chain = () => {
  const navigate = useNavigate();
  const { chainId } = useParams();
  const dispatch = useDispatch();

  const isSignedUp = !!useSelector((state) => state.auth?.token);
  const isOnBoarded = !!useSelector((state) => state.auth?.onBoarded);
  const isOwnedChain = !!useSelector((state) => state.chain.listOfChains?.find(c => c.referenceId === chainId));
  let chain = useSelector((state) => state.chain.chain);
  let loading = useSelector((state) => state.chain.loading);
  let ownChainLoading = useSelector((state) => state.chain?.ownChainLoading);
  let ownChainError = useSelector((state) => state.chain?.ownChainError);


  const [isOpen, setIsOpen] = React.useState(true); // Add isOpen to the component state

  //#region Own Chain 
  const [isOwnChainPopupOpen, setIsOwnChainPopupOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [newBraceletName, setNewBraceletName] = React.useState('');

  const hasMessageCard = chain?.status == 'expired' || (chain?.ownerships);
  const canBeOwned = chain?.status != 'expired';

  useEffect(() => {
    // [].
    // if (chain?.ownerships[chain?.ownerships?.length - 1].created < moment().subtract(1, 'days').format('YYYY-MM-DD')) {
    //   setCanBeGifted(true);
    // }
  }, [chain]);

  //#endregion

  //#region Onboarding
  const [isOnboardingPopupOpen, setIsOnboardingPopupOpen] = React.useState(false);

  useEffect(() => {
    if (!isSignedUp && !isOnBoarded) {
      setIsOnboardingPopupOpen(true);

    }
  }, [isSignedUp]);

  const closeOnboardingPopup = () => {
    setIsOnboardingPopupOpen(false);
    dispatch(finishedOnBoarded())
  }

  const openOnboardingPopup = () => {
    setIsOnboardingPopupOpen(true);
  }

  //#endregion




  const steps = [
    { title: 'First', description: 'Contact Info' },
    { title: 'Second', description: 'Date & Time' },
  ]



  const toast = useToast();



  let lang = useSelector((state) => state.auth?.lang);

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
  });

  useEffect(() => {
    if (ownChainError) {
      showErrorToast(ownChainError);
      dispatch(clearErrors());
    }
  }, [ownChainError]);

  useEffect(() => {
    if (chainId) {
      dispatch(fetchByChainId(chainId));
      if (isSignedUp)
        dispatch(getChains({}));
    }
  }, []);

  useEffect(() => {
    if (chain) {
      setActiveStep(chain?.ownerships?.length - 1);
    }
  }, [chain]);

  const showErrorToast = (error) => {
    toast({
      title: error,
      description: "",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };



  let handleInputMessage = (e) => {
    let inputValue = e.target.value;
    setMessage(inputValue);
  }

  let handleInputBraceletName = (e) => {
    let inputValue = e.target.value;
    setNewBraceletName(inputValue);
  }

  const onConfirmOwnChain = () => {
    if (isSignedUp) {
      dispatch(acceptChain({ chainId: chain.referenceId, message, name: newBraceletName }));
      setIsOwnChainPopupOpen(false);

    }
  }

  const onClickOwnChain = () => {
    if (!isSignedUp) {
      showErrorToast(Localization.chain.mustLoginFirst[lang]);
      dispatch(chainOnboarding({ id: chain.referenceId }));
      navigate(`/login`);
    }
    else {
      setIsOwnChainPopupOpen(true)
    };
  }
  const onOwnChainClose = () => {
    setIsOwnChainPopupOpen(false);
    setMessage('');
  }


  const ownChainPopup = () => {
    return (
      <Modal isOpen={isOwnChainPopupOpen} onClose={onOwnChainClose} size="sm">
        <ModalOverlay />
        <ModalContent
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="45dvh"
          p={3}
          boxShadow="lg"
          borderRadius="lg"
          bg="white"
        >
          <ModalHeader textAlign="center" fontSize="x-large" fontWeight="bold" color="teal.600">
            {Localization.chain.ownChain.title[lang]}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" justifyContent="space-between" p={2}>
            <Textarea
              value={newBraceletName}
              onChange={handleInputBraceletName}
              dir={lang === 'ar' ? 'rtl' : 'ltr'}

              placeholder={Localization.chain.ownChain.name[lang]}
              height="20%"
              borderColor="teal.300"
              focusBorderColor="teal.500"
              mb={4}
              _placeholder={{ color: 'gray.400' }}
            />
            <Textarea
              value={message}
              onChange={handleInputMessage}
              dir={lang === 'ar' ? 'rtl' : 'ltr'}
              placeholder={Localization.chain.ownChain.message[lang]}
              height="60%"
              borderColor="teal.300"
              focusBorderColor="teal.500"
              _placeholder={{ color: 'gray.400' }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={ownChainLoading}
              loadingText={Localization.chain.ownChain.loading[lang]}
              colorScheme="teal"
              variant="solid"
              width="100%"
              mt={2}
              fontWeight="bold"
              _hover={{ bg: 'teal.600' }}
            >
              {Localization.chain.ownChain.button[lang]}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const ChainHeader = () => {
    return (
      <Flex
        h="full"
        w="full"
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        p={4}
        bg="gray.50"
        boxShadow="md"
      >
        <Flex
          w="full"
          direction={lang === 'ar' ? 'row-reverse' : 'row'}
          alignItems="center"
          justifyContent="space-between"
        >
          <Image
            src='https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw3f3e1c6f/productimages/singlepackshot/562731C00_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F5F5F5'
            h="80px"
            w="80px"
            borderRadius="full"
            objectFit="cover"
            boxShadow="sm"
            mr={4}
            ml={4}
          />
          <Box flex="1">
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="teal.800" className={`flex w-full ${lang == 'en' ? 'justify-start' : 'justify-end'}`} marginLeft={'4dvh'} fontSize="2xl" fontWeight="bold">
                {chain?.name}
              </Text>
              {/* {
                chain?.status != 'active' &&
                <Icon
                  position={'absolute'}
                  color="red.500"
                  as={GiBreakingChain}
                  boxSize={6}
                  ml={lang === 'en' ? 'auto' : 'unset'}
                  mr={lang === 'ar' ? 'auto' : 'unset'}
                />
              } */}
            </Flex>
            <Divider my={2} />
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Icon as={PiUsersThreeFill} boxSize={5} color="teal.500" mr={2} />
                <Text color="teal.800" fontSize="lg" fontWeight="medium">
                  {chain?.uniqueOwnersCount}
                </Text>
              </Flex>
              <Text color="teal.800" fontSize="lg" fontWeight="medium">
                {chain?.ageInDays}D
              </Text>
            </Flex>
          </Box>
          <Box className='flex absolute left-3 top-3' onClick={openOnboardingPopup}>
            <Icon as={CgInfo} boxSize={8} color="teal.500" mr={2} />
          </Box>
        </Flex>
      </Flex>
    );
  };

  const calculatedHeight = () => {

    return `${(chain?.ownerships?.length + (hasMessageCard && 1)) * 10 + 2}dvh`
  }


  const messageLevel = () => {

    if (chain?.status == 'expired')
      return (chain?.ownerships) && <Level chain={chain} lang={lang} type={'expired'} index={10} owner={chain?.ownerships[0]} />
    else
      return <Level chain={chain} lang={lang} isGift='gift' index={10} owner={chain?.ownerships[0]} />
  }


  return (
    <>
      <div style={{ height: '90dvh' }}>
        {ownChainPopup()}
        {onBoardingPopup(lang, isOnboardingPopupOpen, closeOnboardingPopup, ownChainLoading)}
        {loading ? LoadingComponent() : (
          <>
            <Grid
              height={'100%'}
              templateRows={'15% 85%'}
            >
              <Box>
                <ChainHeader />

              </Box>
              <Box>
                <div className='flex h-full p-3 overflow-scroll'>

                  <Box
                    size={'lg'}
                    index={activeStep}
                    orientation='vertical'
                    height={calculatedHeight()}
                    width={'100%'}
                    gap='0'
                    overflow={'scroll'}
                    pb={'2dvh'}
                    // bgColor={'lightblue'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyItems={'center'}
                    alignItems={'center'}
                  >
                    {chain?.ownerships?.map((owner, index) => {
                      return (
                        <div className='flex'>
                          <Level type={'ownership'} lang={lang} isLast={index != chain?.ownerships?.length - 1} chain={chain} index={index} owner={owner} />

                        </div>
                      )
                    })}
                    {messageLevel()}

                  </Box>
                </div>
              </Box>
            </Grid>
            {canBeOwned ? (<div style={{}} className={`${isSignedUp ? 'absolute' : 'relative'} h-1/12  flex bottom-0 w-full justify-center items-center`}>
              <Button
                isLoading={ownChainLoading}
                loadingText='Submitting'
                colorScheme='teal'
                variant='solid'
                zIndex={20}
                className='w-11/12 '

                onClick={onClickOwnChain}
              >
                {Localization.chain.ownBracelet[lang]}
              </Button>
            </div>) :
              (!isSignedUp && <div style={{}} className={`${isSignedUp ? 'absolute' : 'relative'} h-1/12  flex bottom-0 w-full justify-center items-center`}>
                <Button
                  isLoading={ownChainLoading}
                  loadingText='Submitting'
                  colorScheme='teal'
                  variant='solid'
                  zIndex={20}
                  className='w-11/12 '

                  onClick={onClickOwnChain}
                >
                  {Localization.chain.getYourOwnBracelet[lang]}
                </Button>
              </div>)}
          </>
        )}
      </div >
      <div>
        {isSignedUp && BottomMenu()}
      </div>
    </>
  );
};
export default Chain;


